import React from 'react';
import {withSlotsPlaceholders} from '@wix/widget-plugins-ooi';
import {IStylesParams} from './styleParams/types';
import {initContext, Strategy, withGlobalsProvider} from './globalPropsContext';
import {withStylesParams} from './stylesParamsContext';

export interface IGetWrappedComponentOptions {
  Component: React.ComponentType;
  globalPropsStrategy: Strategy<any>;
  sliderGallery?: boolean;
  stylesParams: IStylesParams;
}

export function getWrappedComponent({
  Component,
  globalPropsStrategy,
  sliderGallery,
  stylesParams,
}: IGetWrappedComponentOptions) {
  initContext(globalPropsStrategy);
  const ComponentWithGlobals = withGlobalsProvider(Component, sliderGallery);
  const ComponentWithGlobalsAndStyleParams = withStylesParams(ComponentWithGlobals, stylesParams);
  return withSlotsPlaceholders(ComponentWithGlobalsAndStyleParams);
}
